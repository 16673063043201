<script>
import Layout from "../../layouts/main";
import { SEND_EMAIL_VERIFICATION_LINK } from "@/state/actions.type";

/**
 * Verifiy Email Address component
 */
export default {
  data() {
    return {
      error: null,
      linkSentSuccess: false,
      linkSentFailure: false,
    };
  },
  components: { Layout },
  computed: {
    isEmailVerified() {
      return this.$store ? this.$store.getters['auth/isEmailVerified'] : false;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  beforeMount() {
    if(this.isEmailVerified) {
      this.$router.push('/')
    }
  },
  methods: {
    sendEmailVerificationLink() {
      return (
        this.$store
          .dispatch(`auth/${SEND_EMAIL_VERIFICATION_LINK}`)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.linkSentSuccess = true;
          })
          .catch(error => {
            this.error = error ? error : "";
            this.linkSentFailure = true;
          })
      );
    }
  },
  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="container">
              <b-alert
                v-model="linkSentFailure"
                class="mb-4"
                variant="danger"
                dismissible
              >{{ error }}</b-alert>

              <b-alert
                v-model="linkSentSuccess"
                class="mt-3"
                variant="success"
                dismissible
              >{{ notification.message }}</b-alert>

              <div class="row">
                <div class="col-lg-12">
                  <div class="text-center my-5">
                    <img src="https://chaipay-pg-icons.s3.ap-southeast-1.amazonaws.com/verify_email.png" alt class="error-img" width="150" height="150"/>
                    <div class="mt-5 text-center">
                      <h3>{{ "Please verify your email address" }}</h3>
                      <h5>{{ "If you have already verified, please refresh this page or try login again." }}</h5>
                    </div>
                    <div class="mt-5 text-center">
                      <button
                        @click="sendEmailVerificationLink"
                        class="btn waves-effect waves-light"
                        style="background-color:#252B3B; color:#fff"
                      >{{ "Resend Link to verify email" }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
